import { Button } from '@lemonenergy/lemonpie-3'
import { PlayCircle } from '@lemonenergy/lemonpie-icons'
import { Link } from '@remix-run/react'
import { useState, type MouseEvent } from 'react'

import VideoDialog, { linksToWarmVideo } from './VideoDialog'

import HeroBackgroudPattern from 'public/images/bg-home-hero-pattern.svg'
import appRoutes from '~/constants/appRoutes'
import lemonConstants from '~/constants/lemonConstants'
import { appendSearchParamsString } from '~/utils/url.utils'

export const links = () => ({ ...linksToWarmVideo })

const Hero = () => {
  const [isVideoOpened, setIsVideoOpened] = useState(false)

  const handleOpenVideo = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    setIsVideoOpened(true)
  }

  return (
    <>
      <section
        className="grid max-2xl:min-h-[calc(100dvh-4.625rem)] xl:max-h-[calc(100dvh-4.625rem)] h-full w-full bg-limeGreen-5"
        style={{
          backgroundImage:
            'radial-gradient(243.15% 156.81% at 8.13% 144.89%, rgba(124, 232, 70, 0.20) 0%, rgba(244, 252, 237, 0.20) 62.59%)',
        }}
      >
        <div className="w-full h-full col-start-1 row-start-1 overflow-hidden">
          <img
            src={HeroBackgroudPattern}
            alt=""
            className="w-full h-full object-cover"
            aria-hidden
          />
        </div>
        <div className="container col-start-1 row-start-1 px-large py-largest lg:p-96 flex max-lg:flex-wrap max-lg:gap-y-[4.688rem] lg:gap-x-[4.688rem] items-center justify-items-start lg:justify-center 2xl:px-[10.5rem] lg:flex-row">
          <div className="flex flex-col gap-y-larger max-lg:max-w-[300px] lg:max-w-min">
            <div className="flex flex-col gap-smaller">
              <h1 className="text-green-100 font-medium text-[1.75rem] xl:text-[2.25rem] -tracking-[0.075rem] leading-[2.25rem] xl:leading-[2.625rem]">
                Energia&nbsp;solar&nbsp;repensada,
                com&nbsp;economia&nbsp;e&nbsp;sem custos&nbsp;extras
              </h1>
              <h2 className="fg-softer typography-copy-small xl:typography-copy">
                A energia como deve ser
              </h2>
            </div>
            <div className="flex flex-col gap-main justify-start items-start">
              <Button asChild variant="primary" className="w-fit">
                <Link
                  to={appendSearchParamsString(
                    appRoutes.ACQUISITION_FLOW_REGISTER_PERSON_DATA,
                    'utm_source=marketing_midia_on&utm_medium=site&utm_campaign=cta_primeira_dobra',
                  )}
                >
                  Realizar cadastro
                </Link>
              </Button>
              <Button
                variant="tertiary"
                className="bdr-expressive-strong border-main bg-transparent w-fit mix-blend-multiply gap-smallest"
                asChild
              >
                <Link
                  to={lemonConstants.videos.howLemonWorks.href}
                  target="_blank"
                  rel="noopener"
                  onClick={handleOpenVideo}
                >
                  <PlayCircle size="large" />
                  <span>Assistir vídeo</span>
                </Link>
              </Button>
            </div>
          </div>
          <div className="flex max-md:w-full lg:justify-end">
            <img
              src="https://images.energialemon.com.br/site/hero-image.png"
              srcSet="https://images.energialemon.com.br/site/hero-image.png?width=312&format=auto 312w,
          https://images.energialemon.com.br/site/hero-image.png?width=624&format=auto 624w,
          https://images.energialemon.com.br/site/hero-image.png?width=500&format=auto 500w,
          https://images.energialemon.com.br/site/hero-image.png?width=1000&format=auto 1000w,
          https://images.energialemon.com.br/site/hero-image.png?width=450&format=auto 450w,
          https://images.energialemon.com.br/site/hero-image.png?width=900&format=auto 900w,
          https://images.energialemon.com.br/site/hero-image.png?width=698&format=auto 698w,
          https://images.energialemon.com.br/site/hero-image.png?width=1396&format=auto 1396w"
              className="w-full max-lg:w-[31.25rem] lg:max-h-[29.688rem] aspect-[85/58]"
              width={300}
              height={205}
              alt=""
            />
          </div>
        </div>
      </section>
      <VideoDialog
        isOpen={isVideoOpened}
        name="hero-video"
        title={lemonConstants.videos.howLemonWorks.title}
        urlOrId={lemonConstants.videos.howLemonWorks.href}
        onClose={() => setIsVideoOpened(false)}
      />
    </>
  )
}

export default Hero
