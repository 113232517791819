import { fetchEntries } from '@builder.io/sdk-react'
import { json, type MetaFunction } from '@remix-run/node'
import { useLoaderData } from '@remix-run/react/dist/components'
import { captureException } from '@sentry/remix'

import HomeFaq from './__components/HomeFaq'
import HomeHero from './__components/HomeHero'
import LemonAppSection from './__components/LemonAppSection'
import LemonSustainabilitySection from './__components/LemonSustainabilitySection'
import LemonWhatSection from './__components/LemonWhatSection'
import MainFooter from './__components/MainFooter'
import MainHeader from './__components/MainHeader'

import Layout from '~/components/Layout'
import constants from '~/constants/index.server'
import { commonMetaTags } from '~/utils/meta-tags.utils'

export const loader = async () => {
  const articles = await fetchEntries({
    model: constants.builderIOModels.articles,
    apiKey: constants.builderPublicKey,
    query: {
      name: {
        $in: [
          'Como fica a minha conta de luz?',
          'Como a Lemon ganha dinheiro?',
          'Imóvel alugado pode ser Lemon?',
          'E se minha energia acabar?',
        ],
      },
    },
    limit: 4,
  }).catch(error => {
    captureException(error)
    return null
  })

  if (!articles) return json({ questions: null } as const)

  return json({
    questions: articles
      .filter(article => article.data?.question && article.data?.defaultAnswer)
      .map(article => ({
        question: article.data?.question as string,
        answer: article.data?.defaultAnswer as string,
      }))
      .reverse(),
  } as const)
}

export const meta: MetaFunction<typeof loader> = ({ data, location }) => {
  const tags = [
    ...commonMetaTags({
      pathname: location.pathname,
    }),
    {
      'script:ld+json': {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        name: 'Lemon Energia',
        url: 'https://energialemon.com.br/',
        sameAs: [
          'https://www.linkedin.com/company/lemon-energia',
          'https://www.instagram.com/energialemon',
          'https://www.facebook.com/EnergiaLemon',
          'https://maps.app.goo.gl/Qoh28FX5sSPn585y6',
        ],
        logo: 'https://images.energialemon.com.br/branding/logos/simbolo_branco_fundo_verde.png',
        description:
          'A Lemon oferece soluções em energia sustentável para residências e empresas.',
        contactPoint: {
          '@type': 'ContactPoint',
          telephone: '+55-11-91018-8899',
          contactType: 'Atendimento ao Cliente',
          email: 'contato@energialemon.com.br',
          areaServed: 'BR',
          availableLanguage: ['Português'],
        },
        address: {
          '@type': 'PostalAddress',
          streetAddress:
            'Av. Engenheiro Luís Carlos Berrini, 1.748 - Conj. 1.710 - Cidade Monções',
          addressLocality: 'São Paulo',
          addressRegion: 'SP',
          postalCode: '04571-000',
          addressCountry: 'BR',
        },
        legalName: 'LEMON LVM ENERGIAS RENOVÁVEIS LTDA',
        vatID: '33.400.689/0001-09',
        makesOffer: {
          '@type': 'Offer',
          url: 'https://energialemon.com.br/quero-economizar',
          priceCurrency: 'BRL',
          availability: 'https://schema.org/InStock',
          description:
            'Energia limpa sem instalação, sem custo extra e com economia todo mês.',
          itemOffered: {
            '@type': 'Service',
            name: 'Energia Limpa e Barata',
            description:
              'Conectamos pessoas e negócios a usinas de energia solar, promovendo descontos na conta de energia ao oferecermos energia mais barata que a distribuidora tradicional. Nossa energia é mais sustentável por vir de usinas solares próximas, evitando o uso de termoelétricas.',
          },
        },
      },
    },
    {
      'script:ld+json': {
        '@context': 'https://schema.org',
        '@type': 'SoftwareApplication',
        name: 'Lemon Energia - Android',
        operatingSystem: 'Android',
        applicationCategory: 'Utilities',
        downloadUrl: 'https://lmn.page/lemonapp-google-play',
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: '4.3',
          bestRating: '5',
          ratingCount: '83',
        },
      },
    },
    {
      'script:ld+json': {
        '@context': 'https://schema.org',
        '@type': 'SoftwareApplication',
        name: 'Lemon Energia - iOS',
        operatingSystem: 'iOS',
        applicationCategory: 'Utilities',
        downloadUrl: 'https://lmn.page/lemonapp-app-store',
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: '4.9',
          bestRating: '5',
          ratingCount: '48',
        },
      },
    },
  ]

  if (!data?.questions) return tags

  return [
    ...tags,
    {
      'script:ld+json': {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: data.questions.map(question => ({
          '@type': 'Question',
          name: question.question,
          acceptedAnswer: {
            '@type': 'Answer',
            text: question.answer,
          },
        })),
      },
    },
  ]
}

const Index = () => {
  const { questions } = useLoaderData<typeof loader>()
  return (
    <Layout className="bg-white">
      <MainHeader />
      <HomeHero />
      <LemonWhatSection />
      <LemonAppSection />
      <LemonSustainabilitySection />
      <HomeFaq questions={questions} />
      <MainFooter />
    </Layout>
  )
}

export default Index
